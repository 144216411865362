export const FriendSvg = () => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_16_1052)">
        <path
          d="M16.3957 5.35028L16.3958 5.35025C17.0692 4.61636 17.964 4.21469 18.9186 4.20645C18.919 4.20645 18.9194 4.20644 18.9198 4.20644L18.948 4.20636C18.9482 4.20636 18.9485 4.20636 18.9487 4.20636C20.8799 4.20679 22.5077 5.87072 22.5252 7.98854C22.5336 9.0077 22.1696 9.96378 21.5069 10.6859L21.5069 10.6859C20.8334 11.4197 19.9386 11.8215 18.984 11.8297C18.9836 11.8297 18.9832 11.8297 18.9828 11.8297L18.9541 11.8298C18.9539 11.8298 18.9537 11.8298 18.9535 11.8298C18.0095 11.8296 17.1191 11.4434 16.4401 10.7277C15.7656 10.0166 15.3859 9.06662 15.3775 8.04752C15.3691 7.02842 15.733 6.07235 16.3957 5.35028ZM15.5317 21.8234L15.5317 21.8242C15.5412 21.9729 15.5459 22.1233 15.5459 22.2749V22.6828H0.770752V22.2749C0.770752 18.3255 3.97209 15.1242 7.92146 15.1242H8.39575C9.99632 15.1242 11.4725 15.6494 12.6632 16.5368L12.6632 16.5369C14.3014 17.7575 15.3974 19.6607 15.5317 21.8234ZM3.91274 8.01804C3.91274 5.49173 5.84495 3.48633 8.15845 3.48633C10.472 3.48633 12.4042 5.49173 12.4042 8.01804C12.4042 10.5443 10.472 12.5497 8.15845 12.5497C5.84495 12.5497 3.91274 10.5444 3.91274 8.01804ZM18.7541 13.6535H19.1482C22.7995 13.6535 25.7708 16.6249 25.7708 20.2756V21.2925H18.0204C17.9238 20.3335 17.6856 19.4004 17.3083 18.5087C16.8211 17.3564 16.1236 16.3215 15.2363 15.4342C15.0842 15.2819 14.9271 15.1348 14.7652 14.993C15.8761 14.1523 17.2576 13.6535 18.7541 13.6535Z"
          stroke="#EBEBF5"
        />
      </g>
      <defs>
        <clipPath id="clip0_16_1052">
          <rect
            width="26"
            height="26"
            fill="white"
            transform="translate(0.270752 0.0849609)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
