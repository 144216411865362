export const Reloader = ({ className }: { className?: string }) => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2.16667 8.20119L0.568333 8.64769L0.8025 9.40769C1.71667 12.3654 4.6 14.5234 8 14.5234C10.3583 14.5234 12.4575 13.4745 13.8333 11.85V13.7318H15.5V8.98177H10.5V10.5651H12.7867C12.2503 11.299 11.5346 11.8985 10.7009 12.3121C9.86729 12.7258 8.94051 12.9412 8 12.9401C6.73948 12.9411 5.51258 12.554 4.50314 11.8368C3.4937 11.1196 2.75609 10.1109 2.40083 8.96198L2.16667 8.20119ZM8 0.273438C5.64167 0.273438 3.5425 1.3224 2.16667 2.9469V1.0651H0.5V5.8151H5.5V4.23177H3.21333C3.74973 3.49785 4.46541 2.89839 5.29906 2.48475C6.13271 2.07112 7.05949 1.85564 8 1.85677C9.26052 1.85581 10.4874 2.24292 11.4969 2.96012C12.5063 3.67731 13.2439 4.68595 13.5992 5.8349L13.8333 6.59569L15.4317 6.14919L15.1975 5.38919C14.2833 2.43152 11.4 0.273438 8 0.273438Z"
        fill="#FF4C64"
      />
    </svg>
  );
};
