export const SuccessSvg = () => {
  return (
    <svg
      width="131"
      height="131"
      viewBox="0 0 131 131"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="65.5" cy="65.5" r="61.5" stroke="#35CE28" stroke-width="8" />
      <path
        d="M37.1755 63.8717L56.5553 83.203L93.8242 46.0273"
        stroke="#35CE28"
        stroke-width="8"
        stroke-linecap="round"
      />
    </svg>
  );
};
