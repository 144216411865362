export const ArrowSvg = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-4.80825e-07 11C-2.14957e-07 4.91765 4.91765 -7.46693e-07 11 -4.80825e-07C17.0824 -2.14957e-07 22 4.91765 22 11C22 17.0824 17.0824 22 11 22C4.91765 22 -7.46693e-07 17.0824 -4.80825e-07 11ZM20.7059 11C20.7059 5.62941 16.3706 1.29412 11 1.29412C5.62941 1.29412 1.29412 5.62941 1.29412 11C1.29412 16.3706 5.62941 20.7059 11 20.7059C16.3706 20.7059 20.7059 16.3706 20.7059 11Z"
        fill="#8F919D"
      />
      <path
        d="M9.89992 16.3707L15.2705 11.0001L9.89992 5.62952L10.8058 4.72363L17.0823 11.0001L10.8058 17.2766L9.89992 16.3707Z"
        fill="#8F919D"
      />
      <path
        d="M16.1765 10.3525L16.1765 11.6467L5.17651 11.6467L5.17651 10.3525L16.1765 10.3525Z"
        fill="#8F919D"
      />
    </svg>
  );
};
